export default {
    data() {
        return {
            recoveryPasswordSteps: {
                None: 0,
                askIdentification: 1,
                askOtp: 2,
                askNewPassword: 3
            },
            changePasswordResultCode: {
                Success: 0,
                CredentialDoesNotExist: 1,
                WrongCurrentPassword: 2,
                NewPasswordInvalidMinimunLenght: 3,
                NewPasswordInvalidMaximumLenght: 4,
                NewPasswordInvalidPattern: 5,
                NewPasswordUsedPreviously: 6
            }
        }
    },
    computed: {
        currentRecoveryPassword() {
            const actualRecoveryPassword = this.$store.getters["currentRecoveryPassword/currentRecoveryPassword"]; 
            if (actualRecoveryPassword != null)
                return actualRecoveryPassword;
            else 
                return {
                    currentStep: this.recoveryPasswordSteps.None,
                    transactionId: null, 
                    credentialId: null, 
                    email: null,
                    name: null
                };
        }
    },
    methods: {
        async startRecoveryPassword() {
            const initialData = {
                currentStep: this.recoveryPasswordSteps.askIdentification,
                transactionId: null, 
                credentialId: null, 
                email: null,
                name: null
            };
            return this.createOrUpdateRecoveryPassword(initialData);
        },
        async createOrUpdateRecoveryPassword(recoveryData) {
            return this.$store.dispatch("currentRecoveryPassword/createOrUpdate", recoveryData);
        },
        async currentRecoveryPasswordGoForeward() {
            const actualRecoveryPassword = this.currentRecoveryPassword;
            actualRecoveryPassword.currentStep++;
            return this.createOrUpdateRecoveryPassword(actualRecoveryPassword);
        },
        clearRecoveryPassword() {
            return this.$store.dispatch("currentRecoveryPassword/clear");
        },
    }
};