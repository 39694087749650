<template>
  <v-container fill-height fluid>
    <v-row align="center" justify="center">
      <v-col cols="12">
        <v-card
          class="mx-auto pa-3"
          elevation="4"
          outlined
          max-width="350"
          v-if="
            showLogin &&
              currentRecoveryPassword.currentStep == recoveryPasswordSteps.None
          "
        >
          <v-img
            class="ma-0 pa-0"
            :src="require('@/assets/defensoria-logo-nuevo-h.png')"
          ></v-img>

          <div class="subtitle-1 mt-8 mb-5">
            Workflow Gestión de Reclamos DDC
          </div>

          <v-alert
            border="right"
            colored-border
            type="error"
            elevation="2"
            v-if="alert.show"
          >
            {{ alert.message }}
          </v-alert>

          <v-form v-model="form.isValid" @submit.prevent="enter">
            <v-text-field
              label="Rut"
              v-model.trim="formFields.rut"
              required
              :rules="formFields.rutRules"
              maxlength="10"
              autocomplete="off"
              ref="initialFocus"
              :disabled="form.processing"
              placeholder="Sin puntos ni guión"
            />
            <v-text-field
              label="Contraseña"
              v-model.trim="formFields.password"
              required
              :rules="formFields.passwordRules"
              maxlength="15"
              autocomplete="off"
              :disabled="form.processing"
              type="password"
            />
            <v-card-actions>
              <v-btn
                type="submit"
                color="primary"
                block
                :disabled="!form.isValid"
                :loading="form.processing"
                >Ingresar</v-btn
              >
            </v-card-actions>
            <v-card-actions>
              <v-spacer />
              <v-btn
                color="primary"
                text
                small
                @click="initRecoveryPassword"
                :disabled="form.processing"
              >
                Recuperar Contraseña
              </v-btn>
              <router-link
                v-if="false"
                to="#"
                v-on:click.native="initRecoveryPassword()"
                :disabled="form.processing"
                >Recuperar Contraseña</router-link
              >
              <v-spacer />
            </v-card-actions>
          </v-form>
        </v-card>

        <v-card
          class="mx-auto pa-3"
          elevation="4"
          outlined
          max-width="350"
          v-if="
            currentRecoveryPassword.currentStep ==
              recoveryPasswordSteps.askIdentification
          "
        >
          <v-img
            class="ma-0 pa-0"
            :src="require('@/assets/defensoria-logo-nuevo-h.png')"
          ></v-img>

          <div class="subtitle-1 mt-8 mb-5">
            Workflow Gestión de Reclamos DDC
          </div>

          <div class="pa-6 pt-1">
            <div class="h5 text-center mb-4">Recuperar contraseña</div>

            <p class="text-caption text-center">
              Enviaremos un código a su email de contacto para validar la
              solicitud de cambio de contraseña.
            </p>

            <v-alert v-if="alert.show" dense border="left" type="warning">
              {{ alert.message }}
            </v-alert>

            <v-form
              v-model="form.isValid"
              @submit.prevent="prepareSendOtp"
              ref="formGetOtp"
            >
              <p><img src="assets/img/rut.svg" /> Rut (sin puntos ni guión)</p>
              <v-text-field
                autocomplete="false"
                outlined
                v-model.trim="formFields.rutRecoverPassword"
                :label="formFields.rutRecoverPassword | formatRut"
                required
                :rules="formFields.rutRecoverPasswordRules"
                maxlength="9"
                hint="Sin puntos ni guión"
              ></v-text-field>

              <v-btn
                type="submit"
                class="btn-main"
                block
                :disabled="!form.isValid"
                :loading="form.processing"
                >Solicitar código</v-btn
              >
            </v-form>
          </div>

          <div class="text-center">
            <router-link
              to="#"
              v-on:click.native="abort()"
              :disabled="form.processing"
              >Cancelar</router-link
            >
          </div>
        </v-card>

        <v-card
          class="mx-auto pa-3"
          elevation="4"
          outlined
          max-width="350"
          v-if="
            currentRecoveryPassword.currentStep == recoveryPasswordSteps.askOtp
          "
        >
          <v-img
            class="ma-0 pa-0"
            :src="require('@/assets/defensoria-logo-nuevo-h.png')"
          ></v-img>

          <div class="subtitle-1 mt-8 mb-5">
            Workflow Gestión de Reclamos DDC
          </div>

          <div class="pa-6 pt-1">
            <div class="h5 text-center mb-4">Recuperar contraseña</div>

            <v-alert v-if="alert.show" dense border="left" type="warning">
              {{ alert.message }}
            </v-alert>

            <p class="text-center">
              Ingrese el código de 6 dígitos que enviamos al correo
              <span class="text-caption font-italic">{{
                currentRecoveryPassword.email | maskEmail
              }}</span>
            </p>

            <v-form
              v-model="form.isValid"
              @submit.prevent="verifyOtp"
              ref="formVerifyOtp"
            >
              <v-text-field
                autocomplete="false"
                outlined
                v-model.trim="formFields.oneTimePassword"
                :disabled="oneTimePasswordExpired || oneTimePasswordMaxRetries"
                required
                :rules="formFields.oneTimePasswordRules"
                maxlength="6"
              ></v-text-field>
              <v-btn
                type="submit"
                class="btn-main"
                block
                :disabled="
                  !form.isValid ||
                    oneTimePasswordExpired ||
                    oneTimePasswordMaxRetries
                "
                :loading="form.processing"
                >Continuar</v-btn
              >
            </v-form>

            <div
              class="mt-5 text-center"
              v-if="
                !form.processing &&
                  !oneTimePasswordExpired &&
                  !oneTimePasswordMaxRetries
              "
            >
              <router-link
                to="#"
                v-on:click.native="resendOtp()"
                :disabled="form.processing"
                >Volver a solicitar código</router-link
              >
            </div>
            <div
              class="mt-5 text-center"
              v-if="
                !form.processing &&
                  !oneTimePasswordExpired &&
                  !oneTimePasswordMaxRetries
              "
            >
              <router-link
                v-if="false"
                to="#"
                v-on:click.native="gotoContact()"
                :disabled="form.processing"
                >Solicitar código a otro medio</router-link
              >
            </div>

            <div
              class="mt-5 text-center"
              v-if="
                !form.processing &&
                  !oneTimePasswordExpired &&
                  !oneTimePasswordMaxRetries
              "
            >
              <router-link
                to="#"
                v-on:click.native="abort()"
                :disabled="form.processing"
                >Cancelar</router-link
              >
            </div>

            <v-card class="mt-4" elevation="6" v-if="showMessageOtpResended">
              <v-card-text
                ><v-icon>mdi-alert-circle-outline</v-icon> Se acaba de enviar un
                nuevo código</v-card-text
              >
            </v-card>
          </div>
        </v-card>

        <v-card
          class="mx-auto pa-3"
          elevation="4"
          outlined
          max-width="350"
          v-if="
            currentRecoveryPassword.currentStep ==
              recoveryPasswordSteps.askNewPassword
          "
        >
          <v-img
            class="ma-0 pa-0"
            :src="require('@/assets/defensoria-logo-nuevo-h.png')"
          ></v-img>

          <div class="subtitle-1 mt-8 mb-5">
            Workflow Gestión de Reclamos DDC
          </div>

          <div class="pa-6 pt-1">
            <div class="h5 text-center mb-4">Recuperar contraseña</div>

            <v-alert v-if="alert.show" dense border="left" type="warning">
              {{ alert.message }}
            </v-alert>

            <v-form
              v-model="form.isValid"
              @submit.prevent="changePassword"
              ref="formChangePassword"
            >
              <p><img src="assets/img/password.svg" /> Nueva Contraseña</p>
              <v-text-field
                :type="showPassword ? 'text' : 'password'"
                autocomplete="false"
                outlined
                v-model.trim="formFields.newPassword"
                required
                :rules="formFields.newPasswordRules"
                maxlength="20"
                :append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
                @mousedown="showPassword = true"
                @mouseup="showPassword = false"
              ></v-text-field>

              <ul class="mb-6">
                <li>Entre 8 y 20 caracteres</li>
                <li>Debe incluir mayusculas y minusculas</li>
                <li>Debe incluir dígitos</li>
                <li>Debe incluir caracteres especial</li>
              </ul>

              <p>
                <img src="assets/img/password.svg" />Repetir Nueva Contraseña
              </p>
              <v-text-field
                :type="showPassword ? 'text' : 'password'"
                autocomplete="false"
                outlined
                v-model.trim="formFields.confirmNewPassword"
                required
                :rules="formFields.confirmNewPasswordRules"
                maxlength="20"
                :append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
                @mousedown="showPassword = true"
                @mouseup="showPassword = false"
              ></v-text-field>

              <v-btn
                type="submit"
                class="btn-main"
                block
                :disabled="!form.isValid"
                :loading="form.processing"
                >Cambiar contraseña</v-btn
              >
            </v-form>

            <div
              class="mt-5 text-center"
              v-if="
                !form.processing &&
                  !oneTimePasswordExpired &&
                  !oneTimePasswordMaxRetries
              "
            >
              <router-link
                to="#"
                v-on:click.native="abort()"
                :disabled="form.processing"
                >Cancelar</router-link
              >
            </div>
          </div>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import baseViewMixin from "@/mixins/baseView";
import currentUserMixin from "@/mixins/currentUser";
import currentRecoveryPasswordMixin from "@/mixins/currentRecoveryPassword";
import accountsService from "@/services/accounts";
import challengesService from "@/services/challenges";

import sxRut from "sx-rut";

export default {
  mixins: [baseViewMixin, currentUserMixin, currentRecoveryPasswordMixin],
  data() {
    return {
      retry: 0,
      formFields: {
        rut: "",
        rutRules: [
          (value) => !!value || "Requerido",
          (value) =>
            this.allConstants.regex.regexRut.test(value) || "No es válido",
        ],
        password: "",
        passwordRules: [(value) => !!value || "Requerido"],
        rutRecoverPassword: "",
        rutRecoverPasswordRules: [
          (value) => !!value || "Requerido",
          (value) =>
            this.allConstants.regex.regexRut.test(value) || "No es válido",
          (value) => sxRut.isValidRut(value) || "No es válido",
        ],
        oneTimePassword: "",
        oneTimePasswordRules: [
          (value) => !!value || "Requerido",
          (value) =>
            this.allConstants.regex.regexDigits.test(value) || "No es válido",
          (value) => value.length >= 6 || "Mínimo 6 dígitos",
        ],
        newPassword: "",
        newPasswordRules: [
          (value) => !!value || "Requerido",
          (value) => value.length >= 8 || "Mínimo 8 caracteres",
        ],
        confirmNewPassword: "",
        confirmNewPasswordRules: [
          (value) => !!value || "Requerido",
          (value) => value.length >= 8 || "Mínimo 8 caracteres",
          (value) =>
            this.formFields.newPassword == value || "Claves no coinciden",
        ],
      },
      showPassword: false,
      showLogin: true,
      showMessageOtpResended: false,
      oneTimePasswordExpired: false,
      oneTimePasswordMaxRetries: false,
    };
  },
  methods: {
    initRecoveryPassword() {
      this.formFields.rutRecoverPassword = "";
      this.startRecoveryPassword();
    },
    async enter() {
      this.hideAlert();
      this.initSignalProcessing();

      const loginResult = await this.internalLogin(
        this.formFields.rut.toUpperCase(),
        this.formFields.password
      );

      if (
        loginResult.resultCode ==
          this.allConstants.security.loginResultCode.WrongPassword ||
        loginResult.resultCode ==
          this.allConstants.security.loginResultCode.CredentialDoesNotExist
      ) {
        this.showAlert("Rut o contraseña incorrecta");
      }

      if (
        loginResult.resultCode ==
        this.allConstants.security.loginResultCode.CredentialLocked
      ) {
        this.showAlert("Cuenta bloqueada por exceso de intentos fallidos");
        this.formFields.rut = "";
        this.formFields.password = "";
      }

      if (
        loginResult.resultCode ==
        this.allConstants.security.loginResultCode.CredentialExpired
      ) {
        this.showAlert("Cuenta no existe o se encuentra expirada");
        this.formFields.rut = "";
        this.formFields.password = "";
      }

      if (
        loginResult.resultCode ==
        this.allConstants.security.loginResultCode.TokenExpired
      ) {
        this.formFields.rut = "";
        this.formFields.password = "";
        this.$store.dispatch("notifications/setMiddleware");
      }

      if (
        loginResult.resultCode ==
          this.allConstants.security.loginResultCode.Success ||
        loginResult.resultCode ==
          this.allConstants.security.loginResultCode
            .SuccessAndMustChangePassword
      ) {
        this.internalPostLogin();
      }

      this.stopSignalProcessing();
    },
    abort() {
      this.formFields.rut = "";
      this.formFields.password = "";
      this.formFields.rutRecoverPassword = "";
      this.formFields.oneTimePassword = "";
      this.formFields.newPassword = "";
      this.formFields.confirmNewPassword = "";

      this.oneTimePasswordExpired = false;
      this.oneTimePasswordMaxRetries = false;

      this.clearRecoveryPassword();

      this.showLogin = true;

      this.hideAlert();
    },
    async prepareSendOtp() {
      this.hideAlert();
      this.initSignalProcessing();

      const accountFindResult = await this.findAccountV2(
        this.formFields.rutRecoverPassword
      );
      if (!accountFindResult) {
        this.showAlert("No encontramos su rut en nuestros registros");
        this.stopSignalProcessing();
        return;
      }

      const actualRecoveryPassword = this.currentRecoveryPassword;
      //actualRecoveryPassword.credentialId = accountFindResult.account.credentialId;
      //actualRecoveryPassword.email = accountFindResult.account.email;
      //actualRecoveryPassword.name = accountFindResult.account.name;
      await this.createOrUpdateRecoveryPassword(actualRecoveryPassword);

      await this.sendOtpV2();
    },
    async findAccountV2(identificationNumber) {
      try {
        const getAccountResult = await accountsService.exists(
          identificationNumber
        );
        if (getAccountResult.status == 200) {
          return {};
        } else {
          return null;
        }
      } catch (error) {
        return null;
      }
    },
    async findAccount(identificationNumber) {
      try {
        const getAccountResult = await accountsService.getByIdentification(
          identificationNumber
        );
        if (getAccountResult.data && getAccountResult.data.id != "") {
          return getAccountResult.data;
        } else {
          return null;
        }
      } catch (error) {
        return null;
      }
    },
    async sendOtp() {
      const message = {
        messagesInfo: [
          {
            type: "email",
            address: this.currentRecoveryPassword.email,
            attributes: [
              {
                key: "Nombre",
                value: this.currentRecoveryPassword.name,
              },
            ],
          },
        ],
      };

      const sendOtpResult = await challengesService.sendOtp(message);

      const actualRecoveryPassword = this.currentRecoveryPassword;
      actualRecoveryPassword.transactionId = sendOtpResult.data.transactionId;
      await this.createOrUpdateRecoveryPassword(actualRecoveryPassword);
    },
    async sendOtpV2() {
      try {
        this.retry++;
        const sendOtpResult = await accountsService.sendOtp(
          this.formFields.rutRecoverPassword
        );

        const actualRecoveryPassword = this.currentRecoveryPassword;
        actualRecoveryPassword.transactionId = sendOtpResult.data.transactionId;
        actualRecoveryPassword.email = sendOtpResult.data.email;

        await this.createOrUpdateRecoveryPassword(actualRecoveryPassword);
        this.currentRecoveryPasswordGoForeward();
        this.stopSignalProcessing();
      } catch (error) {
        if (this.retry == 5) {
          this.$store.dispatch(
            "notifications/addError",
            "Servicio no disponible. Vuelva a intentar"
          );
          this.retry = 0;
          this.stopSignalProcessing();
        } else {
          setTimeout(() => this.sendOtpV2(), 2000);
        }
      }
    },
    async resendOtp() {
      this.hideAlert();
      this.initSignalProcessing();

      try {
        //await this.sendOtp();
        await this.sendOtpV2();
        this.showMessageOtpResended = true;
        setTimeout(() => (this.showMessageOtpResended = false), 4000);
      } finally {
        this.stopSignalProcessing();
      }
    },
    gotoContact() {
      this.clearRecoveryPassword();
      window.open(
        "https://abif-ddc-front-qa.azurewebsites.net/contacto",
        "_blank"
      );
    },
    async verifyOtp() {
      this.hideAlert();
      this.initSignalProcessing();

      const verifyOtpData = {
        identificationNumber: this.formFields.rutRecoverPassword,
        code: this.formFields.oneTimePassword,
        transactionId: this.currentRecoveryPassword.transactionId,
      };

      //const verifyOtpResult = await challengesService.verifyOtp(verifyOtpData);
      const verifyOtpResult = await accountsService.verifyOtp(verifyOtpData);

      if (verifyOtpResult.data.status == this.allConstants.otp.status.invalid) {
        this.showAlert("El código ingresado no es correcto");
        this.stopSignalProcessing();
        return;
      }

      if (verifyOtpResult.data.status == this.allConstants.otp.status.expired) {
        this.oneTimePasswordExpired = true;
        this.showAlert("Código expirado");
        this.stopSignalProcessing();
        return;
      }

      if (
        verifyOtpResult.data.status == this.allConstants.otp.status.maxRetries
      ) {
        this.oneTimePasswordMaxRetries = true;
        this.showAlert("Código bloqueado por exceso de intentos fallidos");
        this.stopSignalProcessing();
        return;
      }

      const actualRecoveryPassword = this.currentRecoveryPassword;
      actualRecoveryPassword.credentialId = verifyOtpResult.data.credentialId;
      actualRecoveryPassword.token = verifyOtpResult.data.token;
      await this.createOrUpdateRecoveryPassword(actualRecoveryPassword);

      this.currentRecoveryPasswordGoForeward();

      this.stopSignalProcessing();
    },
    async changePassword() {
      this.hideAlert();
      this.initSignalProcessing();

      const checkIsValidPasswordResult = await accountsService.checkIsValidPasswordPattern(
        this.formFields.newPassword
      );

      if (!checkIsValidPasswordResult.data.isValid) {
        this.showAlert("La nueva Contraseña  no cumple con los requerimientos");
        this.stopSignalProcessing();
        return;
      }

      const changePasswordData = {
        credentialId: this.currentRecoveryPassword.credentialId,
        token: this.currentRecoveryPassword.token,
        newPassword: this.formFields.newPassword,
      };

      let forceChangePasswordResult;
      try {
        forceChangePasswordResult = await accountsService.forceChangePassword(
          changePasswordData
        );
      } catch {
        this.$store.dispatch(
          "notifications/addError",
          "El tiempo de cambiar la Contraseña expiro."
        );

        this.stopSignalProcessing();
        this.clearRecoveryPassword();
        this.showLogin = true;
        return;
      }

      if (
        forceChangePasswordResult.data.resultCode ==
        this.changePasswordResultCode.NewPasswordUsedPreviously
      ) {
        this.showAlert(
          "La nueva contraseña ya fue usada con anterior. Ingrese una distinta."
        );
        this.stopSignalProcessing();
        return;
      }

      this.$store.dispatch(
        "notifications/addInfo",
        "Contraseña modificada exitosamente."
      );

      this.stopSignalProcessing();
      this.clearRecoveryPassword();
      this.showLogin = true;
    },
  },
};
</script>

<style lang="scss" scoped></style>
